import { Link, graphql } from "gatsby";
import Image from "gatsby-image";
/** @jsx jsx */
import { css, jsx } from "@emotion/react"; // eslint-disable-line no-unused-vars

import ScrollTrigger from "react-scroll-trigger";
import anime from "animejs";

import Layout from "~layouts/main";
import SEO from "~utils/seo";
import PageTitle from "~components/PageTitle";
import PreFooter from "~components/PreFooter";
import BlockSection from "~components/BlockSection";

export const query = graphql`
  query ServicesPageQuery {
    page: sanityServices {
      services
      toolsData {
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      toolsBuild {
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      toolsDeploy {
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
  }
`;

const ServicesPage = ({ data }) => (
  <Layout>
    <SEO
      title="Services | Paonix"
      description="Using cutting-edge web technologies, we build the fastest and most secure sites possible."
    />
    <PageTitle
      title="What we do."
      subtitle="Using cutting-edge tools, we build the fastest and most secure sites possible."
    />
    <BlockSection invert={true}>
      <h3 className="text-xl text-center mb-8">
        If you chose to work with us, we could:
      </h3>
      <div className="flex flex-wrap w-full">
        {data.page.services.map((service, i) => (
          <div className="md:w-1/2 w-full px-4 mb-8 text-blue-primary" key={i}>
            <div
              className="trans-all p-4 border rounded-sm h-full 
                opacity-75 hover:opacity-100 bg-light shadow-xl hover:shadow-2xl"
            >
              {service}
            </div>
          </div>
        ))}
      </div>
    </BlockSection>
    <section className="p-16">
      <ScrollTrigger
        onEnter={() =>
          // ANIMATE: tools section
          anime({
            targets: ".ani.ani-tools-block",
            opacity: 1,
            delay: anime.stagger(150, { start: 800 }),
          })
        }
      >
        <h2 className="text-3xl font-bold font-sans mb-8">Our Tools</h2>
        <div className="flex flex-wrap">
          {[
            { tools: data.page.toolsData, title: "Data" },
            { tools: data.page.toolsBuild, title: "Build" },
            { tools: data.page.toolsDeploy, title: "Deploy" },
          ].map((t, i) => (
            <div
              className="ani ani-tools-block md:w-1/3 w-full md:mb-0 mb-12 opacity-0"
              key={i}
            >
              <h3 className="font-sans font-bold text-2xl text-center mb-12">
                {t.title}
              </h3>
              {t.tools.map((tool, j) => (
                <div
                  css={css`
                    filter: grayscale(0.85);
                    &:hover {
                      filter: grayscale(0);
                    }
                    max-width: 150px;
                  `}
                  className="trans-all opacity-75 hover:opacity-100 mx-auto mb-12"
                  key={j}
                >
                  <Image fluid={tool.asset.fluid} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </ScrollTrigger>
    </section>
    <PreFooter title="Curious who we are?">
      <p>
        Go on down to the{" "}
        <Link className="button" to="/about">
          about
        </Link>{" "}
        page to see the team.
      </p>
    </PreFooter>
  </Layout>
);

export default ServicesPage;
